import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';

const Skill = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const skillSections = [
    'Frontend Development Skills',
    'Backend Development Skills',
    'Cloud Computing Skills',
    'Development Tools and Methodologies',
    'Soft Skills & Collaboration',
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div onClick={toggleModal}>
              <b>Skill</b>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="container modal-skill my-modals"
        isOpen={isModalOpen}
        toggle={toggleModal}
        style={{ maxWidth: '1000px', width: '100%', height: '100%' }}
      >
        <ModalHeader toggle={toggleModal}>
          <h2 className="about-t">MY SKILLS</h2>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-12 about-tt">
              <Form>
                <h1>My toolbox for Full Stack Development magic employs a full compliment of technologies!</h1>
                <FormGroup>
                  <h1 style={{ color: '#e9d5b8' }}>PROFICIENT</h1>
                </FormGroup>

                {skillSections.map((section) => (
                  <FormGroup key={section}>
                    <h4 title={section}>{section.length > 24 ? `${section.substring(0, 24)}...` : section}</h4>
                    <div className="percent">
                      <div style={{ width: '100%' }}></div>
                    </div>
                  </FormGroup>
                ))}

                <div className="row">
                  <div className="col-12 resume-btn">
                    <button>
                      <a href="https://ttewabe.github.io/html-js/" target="_blank" rel="noreferrer">
                        RESUME
                      </a>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Skill;
